import footerWave1 from '../blobs/wave-footer-1.svg';
import footerWave2 from '../blobs/wave-footer-2.svg';
import copyIcon from '../icons/copyright.svg';
import mailIcon from '../icons/mail.svg';
import phoneIcon from '../icons/phone.svg';
import homeIcon from '../icons/home.svg';
import facebookIcon from '../icons/socials/facebook.svg';
import instagramIcon from '../icons/socials/instagram.svg';
import youtubeIcon from '../icons/socials/youtube.svg';
import { Link } from 'react-router-dom';
import './Footer.scss';

const Footer = () => {
    return (
        <>
        <footer className="footer" style={{backgroundImage: `url(${footerWave1}), url(${footerWave2}) `}}>
            <div className="signature">
                <h1>ALAN <br></br>
                    SŁOMIŃSKI</h1>
            </div>

            <div className="footer__flex1">
                <div className="footer__flex1__element">
                    <h1>Kontakt</h1>
                    <a href="mailto: alanik.trener@gmail.com" className="hover-info">
                        <p>
                            <img src={mailIcon} alt="" /> Alanik.trener@gmail.com
                        </p>
                    </a>
                    <a href="tel:+48576823123">
                        <p>
                            <img src={phoneIcon} alt="" /> +48 576 823 123
                        </p>
                    </a>
                </div>
                <div className="footer__flex1__element">
                    <h1>Oferta</h1>
                    <p>Diety</p>
                    <p>Plany Treningowe</p>
                    <p>Stała Współpraca</p>
                    <p><b>Pakiety</b></p>
                </div>
                <div className="footer__flex1__element">
                    <h1>Linki</h1>
                    <p><b>Twoje Konto</b></p>
                    <p>Blog</p>
                    <p>Pomoc</p>
                    <p><b>FAQ</b></p>
                </div>
            </div>
            <div className="footer__flex2">
                <p>Regulamin</p> 
                <div></div>
                <p>Polityka Prywatności</p>
                <div></div>
                <p>Bezpieczeństwo</p>
            </div>
            <div className="footer__socials">
                <a href="https://pl-pl.facebook.com/people/Alan-S%C5%82omi%C5%84ski/pfbid0Z4UL74YQAnTUctufD5dPaFq9X2X8TQ9DA9Lyr3hEwPSFcEABar2kk61ALQjinDZFl/" target="_blank"><img src={facebookIcon} alt="" /></a>
                <a href="https://www.instagram.com/alanik_trener/" target="_blank"><img src={instagramIcon} alt="" /></a>
                <a href=""><img src={youtubeIcon} alt="" ></img></a>
            </div>
    
        </footer>
        <div className="copyright">
            <p><img src={copyIcon} alt="copyright icon" />2023 Alan Słomiński Inc.</p>
            <span><p>Dev: <a href="https://pl-pl.facebook.com/oskar.kubowidz/" target="_blank" >Oskar</a>, <a href="https://www.instagram.com/mateusz_mleczkoo/" target="_blank" >Mateusz</a></p></span>
        </div>
        </>
    )
}

export default Footer;