import React, { forwardRef } from 'react';
import CloseBtn from './CloseBtn';
import logoImg from '../img/logo.svg';
import mailIcon from '../icons/mail-purple.svg';
import phoneIcon from '../icons/phone-purple.svg';
import facebookIcon from '../icons/socials/facebook-purple.svg';
import instagramIcon from '../icons/socials/instagram-purple.svg';
import './ContactMe.scss';

const ContactMe = forwardRef(({ onCloseClick, className }, ref) => {
  return (
    <div className={className} ref={ref}>
      <CloseBtn onClick={onCloseClick} />
      <img src={logoImg} alt="" className="logo" />
      <a href="tel:+48576823123">
        <img src={phoneIcon} alt="" className="contact-img" />
        <p>+48 576 823 123</p>
      </a>
      <a href="mailto:alanik.trener@gmail.com">
        <img src={mailIcon} alt="" className="contact-img" />
        <p>Alanik.trener@gmail.com</p>
      </a>
      <a
        href="https://pl-pl.facebook.com/people/Alan-S%C5%82omi%C5%84ski/pfbid0Z4UL74YQAnTUctufD5dPaFq9X2X8TQ9DA9Lyr3hEwPSFcEABar2kk61ALQjinDZFl/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={facebookIcon} alt="" className="contact-img" />
        <p>Alan Słomiński</p>
      </a>
      <a href="https://www.instagram.com/alanik_trener/">
        <img src={instagramIcon} alt="" className="contact-img" />
        <p>Alan Słomiński</p>
      </a>
    </div>
  );
});

export default ContactMe;
